.static-content {
  max-width: 1200px;
  margin: 0 auto;
  header {
    margin-bottom: $rhythm * 3;
    h1 {
      @include set-font(06);
    }
    h2 {
      @include set-font(04);
      max-width: 20em;
    }
  }
  p {
    max-width: 35em;
  }
  h4 {
    @include set-font(03);
  }
  ul {
    list-style: none;
    padding: 0;
  }
  .flexitarian {
    margin-bottom: $rhythm * 4;
    .content {
      .image {
        img {
          width: 100%;
          max-width: 42em;
          height: auto;
        }
      }
    }
    @include tablet-plus {
      // @.flexitarian
      display: flex;
      .content {
        @include cols(15);
        @include suffix(1);
      }
      aside {
        @include cols(8);
      }
    }
    @include desktop-plus {
      // @.flexitarian
      .content {
        @include cols(17);
        @include suffix(1);
      }
      aside {
        @include cols(6);
      }
    }
  }
}
