// Box sizing //
*,
*::before,
*::after {
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 $rhythm;
  font-size: 1em;
  font-weight: 400;
}

strong {
  font-weight: 500;
}

hr {
  border-width: 1px 0 0;
  border-color: #000;
}

nav {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
  color: $black;
}

p {
  margin: 0 0 $rhythm;
}

figure,
blockquote {
  margin: 0;
}

.phone-only {
  @include tablet-plus {
    display: none !important;
  }
}

.tablet-plus-only {
  @include phone {
    display: none !important;
  }
}

i.close {
  cursor: pointer;
  position: relative;
  display: block;
  height: 1em;
  width: 1em;
  // transform: translateZ(0);
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 0;
    transform: rotate(45deg);
    border-top: 2px solid $black;
    top: calc(50% - 1px);
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 0;
    transform: rotate(-45deg);
    border-bottom: 2px solid $black;
    bottom: calc(50% - 1px);
  }
  // @include phone {
  //   height: 20px;
  //   width: 20px;
  // }
  // @include tablet-plus {
  //   height: 24px;
  //   width: 24px;
  // }
}

.page {
  position: relative;
  .next-page {
    padding-bottom: $rhythm;
    text-transform: uppercase;
    @include font(30);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    text-align: center;
  }
  &.loading-more {
    .next-page {
      opacity: 1;
    }
  }
}

// .video-player {
//   position: relative;
//   height: 0;
//   padding-top: 56%;
//   .image {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     z-index: 1;
//   }
//   .player {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: center;
//     z-index: 2;
//     // background-color: $black;
//     .play {
//       position: absolute;
//       top: 0;
//       left: 0;
//       right: 0;
//       bottom: 0;
//       z-index: 1;
//       background-color: rgba($black, .2);
//       // background-image: url('../i/play.svg');
//       background-repeat: no-repeat;
//       background-position: center;
//
//       @include phone {
//         background-size: 5rem;
//       }
//       @include tablet-plus {
//         background-size: 6.5rem;
//       }
//     }
//   }
//   iframe {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     z-index: 3;
//   }
// }
.image {
  // article & {
  //   // background: $tan-wash;
  // }
  // article .gallery & {
  //   background: $almost-white;
  // }
  // article aside & {
  //   background: $white;
  // }
  img {
    &:not([src]) {
      opacity: 0;
    }
    // opacity: 0;
    // transition: opacity 400ms;
    // .initialised &,
    // .no-js & {
    //   opacity: 1;
    // }
  }
  &[style*="padding-top"] {
    margin: 0;
    height: 0;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .image-background {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  &.lazyload {
    opacity: 1;
    html:not(.state-initialised) & {
      opacity: 0;
    }
    &.lazyloading {
      opacity: 0;
    }
    &.lazyloaded {
      opacity: 1;
      transition: opacity 700ms;
      transition-delay: 100ms;
    }
  }
  &.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      position: relative;
    }
  }
  &.restrict-width {
    img {
      height: auto;
      width: 100%;
    }
  }
  &.restrict-height {
    height: 100%;
    img {
      height: 100%;
      width: auto;
      position: relative;
    }
  }
  &.grayscale img {
    filter: grayscale(100%);
  }
}

.gallery-items {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  .next,
  .previous {
    background: none !important;
    box-shadow: none !important;
    &:disabled {
      opacity: 0;
    }
    svg {
      width: 11px;
      height: 11px;
    }
  }
  .next svg {
    left: auto;
    right: 0;
  }
  .previous svg {
    right: auto;
    left: 0;
  }
  .flickity-viewport {
    .flickity-slider {
      top: 0;
    }
  }
  .flickity-page-dots {
  }
  .gallery-item:not(.preserve-height) {
    display: inline-block;
    white-space: normal;
    width: 100%;
    text-align: center;
    .aspect-maintainer {
      position: relative;
      height: 0 !important;
      padding-top: 85%; // NOTE this is integral to the way the image is requested
                        //      e.g adding restrict-height/width to image
      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
          pointer-events: none;
        }
      }
    }
  }
  .gallery-item.preserve-height {
    display: inline-block;
    white-space: normal;
    width: 100%;
    text-align: center;
  }
}

.grade {
  position: relative;
  display: inline-block;
  color: $red;
  // border: 3px solid;
  height: 90px;
  min-width: 90px;
  // border-radius: 50%;
  text-transform: uppercase;
  margin: 0;
  strong {
    @include fit-parent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 500;
    @include font(11, 1);
    letter-spacing: .1em;
  }
  i {
    // display: block;
    font-weight: 700;
    font-style: normal;
    @include font(50, .85);

  }
}

.v2 {
  // elements that are used for both v2 and v3 (purge when repair is upgraded)
  display: none !important;
}
