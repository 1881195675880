@mixin webfont($name, $filename, $weight, $style: normal) {
  // see https://css-tricks.com/snippets/css/using-font-face/
  // use like so...
  // @include webfont(whatyouwanttocallit-web, 'filename', weightnumber, italic);
  @font-face {
    font-family: "#{$name}";
    src: url('../../webfonts/' + $filename + '.eot'); // IE9
    src: url('../../webfonts/' + $filename + '.eot?#iefix') format('embedded-opentype'), // IE6-IE8
         url('../../webfonts/' + $filename + '.woff') format('woff'); //, // proper browsers
        //  url('../f/' + $filename + '.woff2') format('woff');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin webfontwoff2($name, $filename, $weight, $style: normal) {
  @font-face {
    font-family: "#{$name}";
    src: url('../../webfonts/' + $filename + '.eot'); // IE9
    src: url('../../webfonts/' + $filename + '.eot?#iefix') format('embedded-opentype'), // IE6-IE8
         url('../../webfonts/' + $filename + '.woff') format('woff'), // proper browsers
         url('../../webfonts/' + $filename + '.woff2') format('woff2'); // super mod!
    font-weight: $weight;
    font-style: $style;
  }
}

// Calibre - no italic
@include webfont(calibre-web, 'CalibreWeb-Regular', 400);
@include webfont(calibre-web, 'CalibreWeb-Medium', 500);
@include webfont(calibre-web, 'CalibreWeb-Semibold', 700);
