html {
  font-size: $base-font-size + 0px;
  line-height: $base-line-height;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  // @include fluid-plus {
  //   $increase-font-size: $base-font-size * 1.1428571429;
  //   font-size: $increase-font-size + 0px;
  // }
}

body {
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  font-feature-settings: 'lnum';
  font-family: 'calibre-web', sans-serif;
  font-weight: 400;
  color: $black;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  // opacity: .0000001;
  // .no-js &,
  // .initialised & {
  //   opacity: 1;
  // }
  @include tablet-portrait-minus {
    &.navigation-visible {
      overflow-y: hidden;
    }
  }

  // > * {
  //   opacity: 0;
  //   .initialised &,
  //   .no-js & {
  //     opacity: 1;
  //   }
  // }

  // Account
  .auxiliary .authenticated,
  .auxiliary .anonymous {
    display: none !important;
  }
  &.state-authenticated .auxiliary .authenticated,
  &.state-anonymous .auxiliary .anonymous {
    @include phone {
      display: block !important;
    }
    @include tablet-plus {
      display: inline-block !important;
    }
  }
  // Cart | Wishlist
  &.state-cart-empty .cart-link .qty,
  &.state-wishlist-empty .wishlist-link .qty {
    display: none !important;
  }
  @include phone {
    .wishlist-link .qty {
      display: none !important;
    }
  }
  &.state-cart-empty .cart-has-items,
  &.state-wishlist-empty .wishlist-has-items {
    display: none !important;
  }
  &.state-cart-has-items .cart-empty,
  &.state-wishlist-has-items .wishlist-empty {
    display: none !important;
  }
}

#header {
  background: $white;
  z-index: 10;
  .top-floor {
    @include grid-container;
    // media q's below @#header
  }
  .secret-floor {
    // media q's below @#header
  }
  .ground-floor {
    @include grid-container;
    // media q's below @#header
  }
  .basement {
    @include grid-container;
    // media q's below @#header
  }
  .brand {
    margin: 0;
    @include set-font(03);
    line-height: .95;
    @include phone {
      // @.brand
      position: fixed;
      top: $rhythm * .5;
      left: 1.2rem;
      z-index: 10;
      &::before {
        content: '';
        background: $white;
        // border-bottom: 1px solid $almost-white;
        // border-bottom: 1px solid $gray-light;
        position: fixed;
        height: $header-height-phone;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
      }
    }
  }
  nav.primary {
    @include set-font(03);
    a {
      span {
        border-bottom: 1px solid transparent;
        transition: border-bottom-color 200ms;
      }
      .no-touchevents &:hover {
        span {
          border-bottom-color: $black;
        }
      }
      &.current,
      &.child-current {
        span {
          border-bottom: 1px solid $black;
        }
      }
    }
    @include tablet-plus {
      //  @.primary
      // min-height: $rhythm * 3.5;
      width: calc(100% - 20rem);
      position: relative;
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 100;
        width: $rhythm * 3;
        background: -moz-linear-gradient(left, rgba($white, 0) 0%, rgba($white, 1) 100%);
        background: -webkit-linear-gradient(left, rgba($white, 0) 0%,rgba($white, 1) 100%);
        background: linear-gradient(to right, rgba($white, 0) 0%,rgba($white, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
      }
      .scrollable {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none;
        }
        &::-moz-scrollbar {
            display: none;
        }
        &::-ms-scrollbar {
            display: none;
        }
      }
      ul {
        white-space: nowrap;
      }
      li {
        display: inline-block;
        vertical-align: top;
        a {
          display: block;
          padding: $rhythm * .5 $rhythm $rhythm * 1.5 0;
        }
      }
    }
  }
  nav.auxiliary {
    @include set-font(01);
    a {
      @include hover-color($gray-medium, 200);
      &.current,
      &.child-current {
        color: $gray-medium;
      }
    }
    @include phone {
      // @.auxiliary
      li {
        .spacer {
          display: block;
          border-bottom: 1px solid $gray-light;
          margin-bottom: $rhythm;
          padding-bottom: $rhythm;
        }
      }
    }
    @include tablet-plus {
      //  @.auxiliary
      li {
        display: inline-block;
        vertical-align: top;
        a {
          display: block;
          padding-left: $rhythm * .5;
        }
        .spacer {
          border-right: 1px solid $hairline;
          padding-right: $rhythm * .5;
        }
      }
    }
  }
  nav.tools {
    @include set-font(02);
    white-space: nowrap;
    li {
      display: inline-block;
      vertical-align: top;
      .quicksearch {
        position: relative;
        z-index: 1000;
        white-space: normal;
        .search {
          position: relative;
          z-index: 1000;
          border: none;
          height: 20px;
          line-height: 20px;
          text-align: right;
          width: auto;
          max-width: 90px;
          background: transparent;
        }
        .results {
          z-index: 1000;
          background: $white;
          width: 15em;
          text-align: left;
          a {
            padding: 0;
            .pointerevents &:hover {
              opacity: .6;
            }
          }
          h2 {
            margin: 10px;
            &:not(:first-of-type) {
              margin-top: $rhythm * 2;
            }
          }
          p {
            margin: 10px;
            &.accessory {
              display: block;
              font-size: .8em;
              width: 12em;
              a {
                color:$gray-medium;
              }
            }
            span {
              display: block;
              color:$gray-medium;
              font-size: .8em;
            }
          }
        }
        .escape {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 999;
          background: rgba($white, .9);
          display: none;
          body.state-search-open & {
            display: block;
          }
        }
      }
      img {
        display: inline-block;
        vertical-align: middle;
        height: 20px;
        width: 23px;
        position: relative;
        top: -2px;
        margin-right: 2px;
      }
      a {
        opacity: 1;
        transition: opacity 300ms;
        &.open {
          opacity: .4;
        }
      }
    }
    @include phone {
      // @.tools
      position: fixed;
      top: .85rem;
      right: $rhythm;
      z-index: 1000;
      li {
        margin-left: 10px;
        .text {
          display: none;
        }
      }
      .quicksearch {
        .search {
          padding: 0;
        }
        .results {
          position: fixed;
          left: 0;
          right: 0;
          width: auto;
          top: 3.5rem;
          h2,
          p {
            margin-left: $rhythm !important;
          }
        }
      }
      .wishlist-link,
      .cart-link {

      }
      .burger {
        top: 5px;
        height: 12px;
        width: 23px;
        position: relative;
        display: block;
        &::before {
          content: '';
          width: 100%;
          position: absolute;
          top: 0px;
          left: 0;
          height: 2px;
          background: $almost-black;
          // transition: 400ms bottom, 400ms transform;
        }
        &::after {
          content: '';
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0px;
          height: 2px;
          background: $almost-black;
          // transition: 400ms bottom, 400ms transform;
        }
        .state-navigation-open & {
          // opacity: .5;
          &::before {
            top: 5px;
            transform: rotate(45deg);
          }
          &::after {
            bottom: 5px;
            transform: rotate(-45deg);
          }
        }
      }
    }
    @include tablet-plus {
      // @.tools
      li {
        .quicksearch {
          .search {
            padding: $rhythm * .7  $rhythm $rhythm * .4 $rhythm;
          }
          .results {
            position: absolute;
            top: calc(100% + 2.1rem);
            right: 0;
          }
        }
        a {
          display: block;
          padding: $rhythm * .7  $rhythm $rhythm * .4 $rhythm;
        }
        &:not(:first-child) {
          border-left: 1px solid $hairline;
        }
        &:last-child {
          a {
            padding-right: 0;
          }
        }
      }
    }
  }
  nav.secondary {
    @include set-font(01);
    ul {
      white-space: nowrap;
    }
    li {
      display: inline-block;
      vertical-align: top;
    }
    a {
      display: block;
      padding: $rhythm * .75 $rhythm * .5 $rhythm * .75 0;
      span {
        border-bottom: 1px solid transparent;
        transition: border-bottom-color 200ms;
      }
      .no-touchevents &:hover {
        span {
          border-bottom-color: $black;
        }
      }
      &.current,
      &.child-current {
        span {
          border-bottom: 1px solid $black;
        }
      }
    }
    @include phone {
      //  @.secondary
      .accounts-page & {
        padding-top: 2.8em;
        // border-bottom: 1px solid $almost-white;
        margin-left: -$rhythm;
        margin-right: -$rhythm;
        padding-left: $rhythm * .5;
        padding-right: $rhythm * .5;
      }
      .listing-page & {
        display: none;
      }
      .catalogue-page & {
        // handled on catalogue, this content is cut/paste into filters
      }
    }
    @include tablet-plus {
      //  @.secondary
      margin-right: $rhythm;
      width: calc(100% - 8rem);
      position: relative;
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 100;
        width: $rhythm * 3;
        background: -moz-linear-gradient(left, rgba($white, 0) 0%, rgba($white, 1) 100%);
        background: -webkit-linear-gradient(left, rgba($white, 0) 0%,rgba($white, 1) 100%);
        background: linear-gradient(to right, rgba($white, 0) 0%,rgba($white, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
      }
      .scrollable {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none;
        }
        &::-moz-scrollbar {
            display: none;
        }
        &::-ms-scrollbar {
            display: none;
        }
      }
    }
  }
  form.sort {
    padding: $rhythm * .7 $rhythm * .5 0 0;
    @include phone {
      // @form.sort
      // for now just hidding this
      display: none;
    }
  }
  section.cart-tool,
  section.wishlist-tool {
    width: 100%;
     > * {
      padding: $rhythm;
    }
    h1 {
      @include set-font(03);
      margin-bottom: 0;
    }
    .cart-has-items,
    .wishlist-has-items {
      .items {
        min-height: $rhythm * 8;
        p,
        h2 {
          margin: 0;
        }
        a {
          @include hover-underline;
          line-height: 1em;
        }
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $gray-light;
            padding-bottom: $rhythm * .5;
            margin-bottom: $rhythm * .5;
            h2 {
              width: 20em;
            }
            .price {
              width: 4em;
            }
          }
        }
        .summary {
          text-align: right;
          .inner {
            display: inline-block;
            text-align: left;
          }
          .total {
            @include font(18, 1.4);
          }
          .shipping,
          .tax {
            @include font(13, 1.4);
          }
        }
      }
      .next {
        @include black-button;
      }
    }
    @include phone {
      // @.cart-tool / @.wishlist-tools
      .cart-has-items,
      .wishlist-has-items {
        .items {
          ul {
            li {
              flex-wrap: wrap;
              justify-content: flex-start;
              .name {
                width: 100%;
              }
              .edit {
                order: 3;
              }
            }
          }
        }
      }
    }
    @include tablet-plus {
      // @.cart-tool / @.wishlist-tools
      // display: flex; // handled by secret-floor (show/hide)
      justify-content: space-between;
      h1 {
        @include cols(6);
        padding-left: 0;
        border-right: 1px solid $hairline;
      }
      .cart-empty,
      .wishlist-empty {
        @include cols(18);
      }
      .cart-has-items,
      .wishlist-has-items {
        $cf-cols: 18;
        $cf-count: 3;
        @include cols($cf-cols);
        display: flex;
        align-items: flex-end;
        .items {
          @include cols(($cf-cols / $cf-count) * 2, $cf-cols);
          padding-right: $rhythm;
          border-right: 1px solid $hairline;
          ul {
            li {
              .price {
                text-align: right;
              }
            }
          }
        }
        .button-group {
          @include cols($cf-cols / $cf-count, $cf-cols);
          padding-left: $rhythm;
        }
      }
    }
  }
  @include phone {
    // @#header
    .secret-floor {
      @include phone-slide;
      .state-secret-floor-open & {
        transform: translateX(0%);
      }
      > * {
        display: none;
        &.transition {
          display: block;
        }
      }
    }
    .phone-floor {
      @include phone-slide;
      .state-navigation-open & {
        transform: translateX(0%);
      }
      .primary,
      .auxiliary {
        padding: $rhythm;
        li {
          margin-bottom: 5px;
        }
      }
    }
  }
  @include tablet-plus {
    // @#header
    position: fixed;
    width: 100%;
    .top-floor {
      display: flex;
      justify-content: space-between;
      padding-top: $rhythm;
      padding-bottom: $rhythm;
    }
    .secret-floor {
      @include grid-container;
      border-top: 1px solid $hairline;
      position: relative;
      top: 1px;
      overflow: hidden;
      transition: 600ms height 0s;
      height: 0;
      .state-secret-floor-open & {
        // height: 500px; // set with js
      }
      > * {
        display: none;
        &.transition {
          display: flex;
        }
      }
    }
    .ground-floor {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $hairline;
      border-bottom: 1px solid $hairline;
      // padding-bottom: $rhythm * .5;
    }
    .basement {
      position: relative;
      // top: -1px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $hairline;
    }
  }
}

#content {
  position: relative;
  z-index: 1;
  @include grid-container;
  @include phone {
    // @#content
    padding-top: $rhythm * 4; // bufffer for fixed header
  }
  @include tablet-plus {
    // @#content
    padding-top: $header-buffer; // bufffer for fixed header
    // background: red;
  }
}

#footer {
  @include grid-container;
  border-top: 1px solid $hairline;
  padding-top: $rhythm;
  padding-bottom: $rhythm * 2;
  a {
    @include hover-color($gray-medium, 200);
  }
  hr {
    // v2
    display: none;
  }
  .primary {
    a {
      &.current,
      &.child-current {
        color: $gray-medium;
      }
    }
  }
  .address,
  .contact {
    p {
      margin: 0;
    }
  }
  .social {
    margin-bottom: $rhythm;
    a {
      display: block;
    }
  }
  @include phone {
    // @#footer
    .address {
      margin-top: $rhythm * 3;
      margin-bottom: $rhythm * 3;
    }
  }
  @include tablet-plus {
    // @#footer
    display: flex;
    justify-content: space-between;
    .primary {
      li {
        display: inline-block;
        a {
          margin-right: $rhythm * .5;
        }
      }
    }
    .address {
      width: 33%;
    }
    .details {
      width: 70%;
      display: flex;
      justify-content: space-between;
      .address,
      .contact {
        width: 33%;
      }
    }
  }
}

#overlay {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  background: $white;
  transform: translateZ(0); //hardware accelerated
  transition: opacity 200ms;
  transition-delay: 700ms;
  .state-secret-floor-open &,
  .state-navigation-open & {
    pointer-events: all;
    opacity: .7;
    transition-delay: 0ms;
  }
}
