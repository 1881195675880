.accounts-content {
  margin-bottom: $rhythm * 5;
  h1 {
    @include set-font(05);
    text-align: center;
  }
  p {
    max-width: 24em;
  }
  a {
    @include hover-underline;
  }
  form {
    max-width: 450px;
    margin: 0 auto;
    input[type='submit']:not(.remove) {
      @include black-button;
      padding: 12px;
      max-width: 200px;
      margin-bottom: $rhythm;
    }
  }
}



// ripped from v2 (and altered)
.account-orders {
  max-width: 1000px;
  margin: 0 auto;
  ul, ul li {
    list-style: none;
    margin: 0;
    padding: 0;

  }
  > ul {
    > li {
      @include clearfix;
      &:not(:first-child) {
        border-top: 1px solid $hairline;
      }
      h6 {
        margin: 0 0 10px;
        @include set-font(03);
      }
      h4, .order-status, ul li .line-total, .order-total {
        margin: 0 0 10px;
        font-size: 20px;
        @include set-font(02);
      }
      ul {
        padding: 0px;
      }
      ul li {
        max-width: 400px;
        @include clearfix;
        padding: 0 0 5px;
        > div {
          float: left;
          min-width: 225px;
        }
        p {
          @include set-font(00);
          clear: both;
        }
        h4, p {
          margin: 0;
        }
        .line-total {
          float: left;
        }
      }
    }
  }
  @include tablet-plus {
    .order-status {
      float: left;
      width: 50%;
    }
    .order-total {
      float: right;
    }
  }
}
