// NOTE cart /checkout page all in one

.payment-content,
.payment-confirm-content,
.success-content {
  header {
    h1 {
      @include set-font(05);
    }
  }
  form {
    .confirm {
      margin-bottom: $rhythm * 2;
      p {
        margin: 0;
      }
    }
    a {
      @include hover-underline;
    }

  }
  .order {
    border-bottom: 1px solid $hairline;
    padding-top: $rhythm;
    padding-bottom: $rhythm;
    .customer {
      margin-bottom: $rhythm * 2;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $hairline;
      padding-bottom: $rhythm * .5;
      margin-bottom: $rhythm * .5;
      > * {
        width: 10em;
      }
      .product {
        width: 14em;
      }
      .quantity {
        text-align: right;
        input {
          width: 3em;
          margin-right: 4px;
        }
      }
      .price {
        text-align: right;
      }
      .remove {
        text-align: right;
      }
      button {
        @include hover-underline-button;
      }
    }
    
    .discounts {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .code {
        width: calc(100% - 10em);
        label {
          display: block;
        }
        [name='codes'] {
          max-width: 400px;
          display: inline-block;
          margin-bottom: $rhythm;
          margin-right: 4px;
        }
        input[type='submit'] {
          @include hover-underline-button;
        }
      }
      .value {
        &::before {
          content: '-';
        }
      }
    }
    .attributes {
      padding-top: $rhythm;
      text-align: right;
    }
    .total {
      margin-top: $rhythm * .25;
      h1 {
        @include set-font(03);
      }
      text-align: right;
    }
  }

  @include tablet-plus {
    // @.cart-page
    header {
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
    }
    .order {
      width: 100%;
      max-width: 900px;
      margin: 0 auto
    }
  }
}

.payment-content {
  .checkout-next {
    a,
    input[type='submit'] {
      @include black-button;
      padding: 12px;
      max-width: 200px;
      margin-bottom: $rhythm;
      display: inline-block;
      margin-right: 2em;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}