.preview-content {
  .preview-stack {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    > * {
      display: block;
      border-top: 1px solid $hairline;
    }
    .product {
      padding: $rhythm * .75;
      @include hover-color($gray);
      header {
        margin-bottom: $rhythm * .5;
        h2 {
          @include set-font(03);
        }
      }
    }
    .listing {
      padding: $rhythm * .75;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include hover-color($gray);
      header {
        margin-bottom: $rhythm * .5;
        h2 {
          @include set-font(03);
        }
      }
      footer {
        margin-top: $rhythm * .5;
        > * {
          @include set-font(03);
          line-height: 1.1;
        }
      }
    }
    .text-item,
    .blog-item {
      padding: $rhythm * .75;
      .pre {
        @include set-font(03);
        min-height:2em;
      }
      // h2 {}
      .text {
        max-width: 20em;
        margin-bottom: $rhythm * 2;
        line-height: 1.3;
      }
    }
    .text-item {
      h2 {
        @include set-font(06);
        margin-top: $rhythm * 3;
        max-width: 6em;
      }
    }
    .blog-item {
      h2 {
        @include set-font(05);
        margin-top: $rhythm * 2;
        max-width: 6em;
      }
      &.first-item {
        .pre {
          max-width: 5em;
        }
      }
    }
    .feature-gallery {
      position: relative;
      .gallery-items {
        overflow: visible;
        @include fit-parent;
        .flickity-viewport {
          // height is established by the flexbox;
          height: 100% !important;
        }
        .flickity-page-dots {
            width: auto;
            right: 0;
            .dot {
              background: $gray;
              margin-right: 0;
            }
        }
      }
      .gallery-item {
        text-align: left;
        @include fit-parent;
        a {
          @include fit-parent;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          padding: $rhythm;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          h2 {
            max-width: 4.37em;
          }
          .text {
            max-width: 9.8em;
          }
        }
      }
      @include phone {
        // @.feature-gallery
        height: calc(75vw - #{$rhythm * 2});
        .gallery-item {
          h2 {
            @include set-font(03);
          }
          .text {
            @include set-font(01);
          }
        }
      }
      @include tablet-plus {
        // @.feature-gallery
        // height is calculated by items to left of el
        .gallery-item {
          h2 {
            @include set-font(06);
          }
          .text {
            @include set-font(03);
          }
        }
      }
    }
    @include phone {
      // @.preview-stack
      // margin-left: -$rhythm;
      // margin-right: -$rhythm;
      > * {
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
        &:first-child {
          border-top: 0;
        }
      }
      .leadin {
        h2 {
          @include font(54, .8);
          max-width: 10em;
        }
      }
      .listing {
        width: 50%;
        margin-bottom: $rhythm * 2;
        // &:nth-child(11),
        // &:nth-child(12),
        // &:nth-child(17),
        // &:nth-child(18) {
        //   border-top: 0;
        // }

        &.phone-align-left:not([class*=widow-]),
        &.widow-1 {
          border-right: 1px solid $hairline;
          padding-right: $rhythm !important;
        }
        &.phone-align-right:not([class*=widow-]),
        &.widow-2 {
          padding-left: $rhythm !important;
        }
        // push the widows to the end
        &.widow-1 {
          order: 2;
        }
        &.widow-2 {
          order: 2;
        }
      }
      .text-item.free-shipping {
        border-top: 1px solid $hairline;
      }
      .blog-item.first-item {
        border-top: 1px solid $hairline;
      }
    }
    @include tablet-plus {
      // @.preview-stack
      > * {
        @include cols(6);
        min-height: 400px;
        margin-bottom: $rhythm * 2.5;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(12),
        &:nth-child(13),
        &:nth-child(14),
        &:nth-child(16),
        &:nth-child(17),
        &:nth-child(18),
        &:nth-child(20),
        &:nth-child(21),
        &:nth-child(22) {
          border-right: 1px solid $hairline;
        }
        &:nth-child(20),
        &:nth-child(21),
        &:nth-child(22),
        &:nth-child(23) {
          border-bottom: 0;
        }
      }
      .leadin {
        padding-top: $rhythm;
        h2 {
          @include set-font(06);
          max-width: 10em;
        }
      }
      .product {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .feature-gallery {
        @include cols(12);
      }
    }
  }
  .newsletter {
    border-top: 1px solid $hairline;
    @include set-font(03);
    .flex-group {
      display: flex;
    }
    #{$inputs} {
      padding: $rhythm 0;
      background: none;
      border: 0;
      color: $black;
      text-align: center;
      &::placeholder {
        color: $black;
      }
    }
    [type='submit'] {
      border: 0;
      background: none;
      font-family: helvetica, arial;
      opacity: 0;
      transition: opacity 300ms;
      color: $black;
      text-align: right;
    }
    input:focus + [type='submit'] {
      opacity: 1;
    }
    @include phone {
      // @.newsletter
      #{$inputs} {
        padding-left: 2em;
        width: calc(100% - 2em);
      }
      [type='submit'] {
        width: 2em;
      }
    }
    @include tablet-plus {
      // @.newsletter
      margin-top: $rhythm * 5;
      #{$inputs} {
        padding-left: 5em;
        width: calc(100% - 5em);
      }
      [type='submit'] {
        width: 5em;
      }
    }
  }
}
