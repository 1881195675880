//

// NOTE this page is used for both listings and accessories

//
.listing-page {
  .listing-content {
    // primary content
    .meat {
      @include phone {
        // @.meat
        .action {
          margin-bottom: $rhythm * 2;
          border-bottom: 1px solid $hairline;
        }
      }
      @include tablet-plus {
        // @.meat
        display: flex;
        align-items: stretch;
        justify-content:space-between;
        margin-bottom: $rhythm * 4;

        > * {
          min-height: 50vh;
          // margin-bottom: $rhythm * 2;
        }
        header {
          border-right: 1px solid $hairline;
          @include cols(6);
          padding-right: $rhythm;
        }
        figure {
          border-right: 1px solid $hairline;
          @include cols(6);
        }
        .detail {
          border-right: 1px solid $hairline;
          @include cols(6);
          padding: 0 $rhythm $rhythm;
        }
        .action {
          @include cols(6);
          padding: 0 0 $rhythm $rhythm;
        }
      }
    }
    header {
      h1 {
        @include set-font(05);
      }
      h2 {
        @include set-font(04);
      }
    }
    figure.listing-gallery {
      .gallery-items {
        position: relative;
        .gallery-item {
          vertical-align: top;
        }
        &.flickity-enabled {
          overflow: visible;
        }
        .flickity-page-dots {
          bottom: auto;
          position: relative;
          margin-top: 5px;
          .dot {
            background: $gray;
            // margin-right: 0;
          }
        }
      }
      .notes,
      .images-fine-print {
        margin-top: $rhythm * .25;
        margin-bottom: $rhythm * .5;
        text-align: left;
        @include set-font(00);
        max-width: 20em;
      }
      @include tablet-plus {
        // @figure.listing-gallery
        .notes,
        .images-fine-print {
          margin: $rhythm * .5;
        }
      }
    }
    .detail {
      @include font(14, 1.15);
      .description,
      .benefits {
        // cms content
        margin-bottom: $rhythm;
        > * {
          max-width: 20em;
        }
        strong {
          font-weight: 400;
          display: inline-block;
          margin-bottom: 0;
          @include set-font(02);
        }
      }
      .rating {
        margin-bottom: $rhythm;
        .grade {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }
        .description {
          display: inline-block;
          vertical-align: middle;
          @include set-font(00);
          width: calc(100% - 100px);
          margin: 0;
        }
      }
      .technicians-report {
        h2 {
          @include set-font(02);
          margin-bottom: 0;
        }
      }
      @include phone {
        // .details
        border-top: 1px solid $hairline;
        border-bottom: 1px solid $hairline;
        padding-top: $rhythm;
        margin-bottom: $rhythm;
      }
    }
    .action {
      .price {
        @include font(33, .95);

        afterpay-placement {
          margin: 5px 0 0;
          font-size: .9rem;

          .afterpay-paragraph {
            font-size: .9rem;
          }
        }

        .free-warranty {
          display: block;
          font-size: .9rem;
          margin-top: 5px;
        }
      }
      .sold-out {
        @include set-font(02);
        color: $gray-medium;
      }
      // form {}
      #notify-me {
        margin-bottom: 10px;
        input[name=email] {
          max-width: 302px;
        }
        .g-recaptcha {
          margin-bottom: 10px;
        }
        input[type=submit] {
          @include black-button;
          padding: 12px;
          max-width: 200px;
        }
        & + #wishlist-form {
          input[type=submit] {
            padding: 12px;
            max-width: 200px;
          }
        }
      }
      #buy-now-form {
        margin-bottom: 10px;
        .form-field {
          margin-top: 0;
          margin-bottom: $rhythm * 2;
        }
        .help-link {
          font-size: .9em;
          @include hover-underline;
        }
        .radio-field {
          margin-bottom: 4px;
          display: block;
          .inline-title {
            width: calc(100% - 5em);
            display: inline-block;
            vertical-align: top;
          }
          .inline-price {
            width: 5em;
            display: inline-block;
            text-align: right;
            vertical-align: top;
          }
          input[type="checkbox"] + .fake {
            top: .26em;
          }
        }
        input[type=submit],
        .added-message {
          @include black-button;
        }
        .added-message {
          display: none;
          background: $black !important;
        }
        &.state-added {
          input[type=submit] {
            display: none;
          }
          .added-message {
            display: block;
          }
        }
      }
      #wishlist-form {
        input[type=submit] {
          @include naked-button;
        }
      }
      @include tablet-plus {
        // @.action
        form {
          max-width:23rem;
        }
      }
    }
    // secondary content
    .salad {
      .snapshot {
        .summary-checklist {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          align-items: stretch;
          justify-content:space-between;
          flex-wrap: wrap;
          li {
            p {
              margin: 0;
              max-width: 8em;
              line-height: 1em;
              &.value {
                margin-top: $rhythm * .25;
              }
            }
            .tick {
              width: 17px;
              height: 16px;
            }
          }
        }
        .tech-spec-triggers {
          a {
            @include hover-underline;
            &.open {
              border-bottom-color: transparent;
            }
          }
          @include phone {
            // .tech-spec-triggers
            border-top: 1px solid $hairline;
            padding-top: $rhythm * .5;
          }
        }
        @include phone {
          // @.snapshot
          .summary-checklist {
            li {
              width: 50%;
              padding: $rhythm * .5;
              &:nth-child(odd) {
                border-right: 1px solid $hairline;
              }
              &:not(:nth-child(1)):not(:nth-child(2)) {
                border-top: 1px solid $hairline;
              }
            }
          }
          .tech-spec-triggers {
            margin-top: $rhythm * 2;
            margin-bottom: $rhythm * .5;
            ul {
              display: flex;
              justify-content: center;
              li {
                padding-right: $rhythm * .5;
                padding-right: $rhythm * .5;
              }
            }
          }
        }
        @include tablet-plus {
          // @.snapshot
          display: flex;
          align-items: stretch;
          justify-content:space-between;
          margin-bottom: $rhythm * 7;
          .summary-checklist {
            li {
              padding: $rhythm * .5 $rhythm $rhythm * .5;
              &:not(:last-child) {
                border-right: 1px solid $hairline;
              }
            }
          }
          .tech-spec-triggers {
            ul {
              display: flex;
              align-items: stretch;
              justify-content:space-between;
              li {
                padding: $rhythm * .5 $rhythm * 2 $rhythm * .5 $rhythm;
                border-left: 1px solid $hairline;
              }
            }
          }
        }
      }
      .tech-specs {
        > * {
          display: none;
          &.open {
            display: block;
          }
        }
      }
      #specs {
        padding-bottom: $rhythm * 2;
        border-top: 1px solid $hairline;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          align-items: stretch;
          justify-content: flex-start;
          flex-wrap: wrap;
          li {
            width: 15em;
            margin-right: $rhythm;
            margin-bottom: $rhythm;
            h2 {
              @include set-font(03);
            }
          }
        }
        @include phone {
          // @#specs
          padding-top: $rhythm;
        }
        @include tablet-plus {
          // @#specs
          margin-top: $rhythm * 2;
          padding-top: $rhythm * 2;
          ul {
            justify-content: space-around;
            li {
              margin-bottom: $rhythm * 3;
            }
          }
        }
      }
      #product-comparison {
        padding-top: $rhythm * 2;
        padding-bottom: $rhythm * 2;
        border-top: 1px solid $hairline;
        .scrollable {
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }
        table {
          margin: 0 auto;
          width: 100%;
          max-width: 1600px;
          tr {
            &:first-child td {
              font-weight: 500;
            }
            &:nth-child(even) td {
              background: $pretty-much-white;
            }
            td {
              padding: $rhythm * .5;
              min-width: 9em;
              &:first-child {
                text-align: left !important;
                font-weight: 500;
              }
            }
          }
        }
        @include phone {
          // @#product-comparison
          .scrollable {
            margin-left: -$rhythm;
            margin-right: -$rhythm;
          }
          table {
            margin-left: $rhythm;
            margin-right: $rhythm;
          }
        }
        @include tablet-portrait {
          // @#product-comparison
          .scrollable {
            margin-left: inset($negative: true);
            margin-right: inset($negative: true);
          }
          table {
            margin-left: inset();
            margin-right: inset();
          }
        }
        @include tablet-plus {
          // @#product-comparison
          margin-top: $rhythm * 2;
        }
      }
      @include tablet-plus {
        // @.salad
        border-top: 1px solid $hairline;
      }
    }
    #related-products {
      margin-bottom: $rhythm * 5;
      h3 {
        @include set-font(05);
      }
      .items {
        .product {
          @include product-listing;
        }
      }
      @include phone {
        // @#related-products
        margin-top: $rhythm * 4;
        .items {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          .product {
            padding: 0;
            width: 50%;
            &:nth-child(1) {
              padding-right: $rhythm * .75;
            }
            &:nth-child(2) {
              padding-left: $rhythm * .75;
              border-left: 1px solid $hairline;
            }
            &:nth-child(3) {
              display: none;
            }
          }
        }
      }
      @include tablet-plus {
        // @#related-products
        border-top: 1px solid $hairline;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        h3 {
          @include cols(6);
          padding: $rhythm * .75;
          border-right: 1px solid $hairline;
          span {
            display: block;
            max-width: 4.976em;
          }
        }
        .items {
          $cf-cols: 18;
          $cf-count: 3;
          @include cols($cf-cols);
          display: flex;
          flex-direction: row;
          align-items: stretch;
          .product {
            @include cols($cf-cols/$cf-count, $cf-cols);
            &:not(:nth-child(#{$cf-count})) {
              border-right: 1px solid $hairline;
            }
          }
        }
      }
    }
    .related-models {
      margin-top: -$rhythm * 2;
      margin-bottom: $rhythm * 5;
      h3 {
        @include set-font(03);
        border-bottom: 1px solid $hairline;
        padding-bottom: $rhythm;
        margin-bottom: 0;
      }
      nav {
        position: relative;
      }
      .scrollable {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none;
        }
        &::-moz-scrollbar {
            display: none;
        }
        &::-ms-scrollbar {
            display: none;
        }
      }
      .inside {
        // NOTE overflows on phone etc
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        a {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-width: 120px;
          max-width: 400px;
          padding: $rhythm * .75;
          flex-grow: 1;
          flex-basis: 0;
          &:not(:first-child) {
            border-left: 1px solid $hairline;
          }
          h2 {
            // @include set-font();
            line-height: 1em;
            min-height: 2.2em;
          }
          .image {
            width: 100%;
          }
        }
      }
      @include phone {
        // @.related-models
        nav {
          margin-left: -$rhythm;
          margin-right: -$rhythm;
          .inside {
            margin-left: $rhythm;
            margin-right: $rhythm;
          }
        }
      }
      @include tablet-plus {
        // @.related-models
        nav {
          margin-left: inset($negative: true);
          margin-right: inset($negative: true);
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            z-index: 100;
            width: $rhythm * 2;
            background: -moz-linear-gradient(left, rgba($white, 1) 0%, rgba($white, 0) 100%);
            background: -webkit-linear-gradient(left, rgba($white, 1) 0%,rgba($white, 0) 100%);
            background: linear-gradient(to right, rgba($white, 1) 0%,rgba($white, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
          }
          &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 100;
            width: $rhythm * 3;
            background: -moz-linear-gradient(left, rgba($white, 0) 0%, rgba($white, 1) 100%);
            background: -webkit-linear-gradient(left, rgba($white, 0) 0%,rgba($white, 1) 100%);
            background: linear-gradient(to right, rgba($white, 0) 0%,rgba($white, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
          }
          .inside {
            margin-left: inset();
            margin-right: inset();
            a {
              max-width: columns(6);
            }
          }
        }
      }
    }
  }
}
