.catalogue-page {
  .catalogue-content {
    @include phone {
      // @.catalogue-content
      margin-top: $rhythm;
    }
    @include tablet-plus {
      // @.catalogue-content
      margin-bottom: $rhythm * 5;
      display: flex;
      align-items: stretch;
    }
    @include desktop-plus {
      // @.catalogue-content
    }
  }
  .catalogue-filters {
    .search {
      width: calc(100% - 30px);
    }
    .filter {
      &.range-filter {
        .input-wrap {
          display: block;
          margin-right: $rhythm * 2;
        }
      }
      .radio-field {
        display: block;
        margin-top: .5em;
        margin-bottom: .5em;
        label {
          @include set-font(00);
          .no-touchevents &:hover {
            color: $gray;
          }
        }
      }
    }
    @include phone {
      // @.catalogue-filters
      margin-bottom: $rhythm * 2;
      border-bottom: 1px solid $hairline;
      position: fixed;
      z-index: 1000;
      top: $header-height-phone;
      left: 0;
      right: 0;
      background: $white;
      @include grid-container;
      .filter-master {
        > .filter-trigger {
          border-top: 1px solid $hairline;
          @include set-font(02);
          padding: $rhythm * .3 $rhythm * 2 $rhythm * .3 0;
          margin: 0;
          position: relative;
          &::after {
            position: absolute;
            right: 3px;
            top: $rhythm * .6;
            // margin: .4em 0 0 20px;
            content: 'â¼';
            font-size: .6rem;
            line-height: 1em;
            font-family: arial, helvetica;
          }
        }
        > .filter-target {
          // padding-top: $rhythm;
          // padding-bottom: $rhythm * .5;
          display: none;
        }
        &.state-filter-open {
          > .filter-trigger {
            &::after {
              transform-origin: 50% 40%;
              transform: rotateX(180deg);
            }
          }
          > .filter-target {
            display: block;
          }
        }
      }
      .filter {
        .filter-trigger {
          display: block;
          border-top: 1px solid $hairline;
          @include set-font(02);
          padding: $rhythm * .3 $rhythm * 2 $rhythm * .3 0;
          margin: 0;
          position: relative;
          &::after {
            position: absolute;
            right: 3px;
            top: $rhythm * .6;
            // margin: .4em 0 0 20px;
            content: 'â¼';
            font-size: .6rem;
            line-height: 1em;
            font-family: arial, helvetica;
          }
        }
        .filter-target {
          border-top: 1px solid $hairline;
          padding-top: $rhythm * .5;
          padding-bottom: $rhythm * .5;
          display: none;
        }
        &.state-filter-open {
          .filter-trigger {
            &::after {
              transform-origin: 50% 40%;
              transform: rotateX(180deg);
            }
          }
          .filter-target {
            display: block;
          }
        }
        &.range-filter {
          .filter-target {
            padding-top: $rhythm;
            padding-bottom: $rhythm * 2;
          }
        }
        .secondary {
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
              margin-bottom: 4px;
              &.blank {
                display: none;
              }
              // a.child-current,
              a.current {
                border-bottom: 1px solid $black;
              }
            }
          }
        }
      }
    }
    @include tablet-plus {
      // @.catalogue-filters
      padding-bottom: $rhythm;
      border-right: 1px solid $hairline;
      padding-right: $rhythm * .75;
      margin-top: -$header-buffer;
      // [data-sb-created] {
        // push the content out
        #catalogue-filter-form,
        #catalogue-filter-nav {
          padding-top: $header-buffer;
          padding-bottom: $rhythm * 2;
        }
      // }
      .filter {
        margin: $rhythm * .75 0;
        &:last-child {
          padding-bottom: $rhythm * 2;
        }
        &:not(:last-child) {
          padding-bottom: $rhythm * .5;
          border-bottom: 1px solid $hairline;
        }
        h2 {
          margin: 0 0 $rhythm * .5;
          @include set-font(00);
        }
        a {
          span {
            border-bottom: 1px solid transparent;
            transition: border-bottom-color 200ms;
          }
          .no-touchevents &:hover {
            span {
              border-bottom-color: $black;
            }
          }
          // &.child-current,
          &.current {
            span {
              border-bottom: 1px solid $black;
            }
          }
        }
      }
      #catalogue-filter-nav {
        .filter {
          margin: 0;
          padding: 0;
          // ul > li {}
          border: none;
          &:last-child {
            border: none;
          }
          .filter-trigger {
            display: block;
            border-bottom: 1px solid $hairline;
            @include set-font(02);
            padding: $rhythm * .5 $rhythm * 2 $rhythm * .5 0;
            margin: 0;
            position: relative;
            &::after {
              position: absolute;
              right: 9px;
              top: $rhythm * .8;
              // margin: .4em 0 0 20px;
              content: 'â¼';
              font-size: .6rem;
              line-height: 1em;
              font-family: arial, helvetica;
            }
          }
          .filter-target {
            // border-top: 1px solid $hairline;
            padding-top: $rhythm * .5;
            padding-bottom: $rhythm * .5;
            display: none;
          }
          &.state-filter-open {
            .filter-trigger {
              &::after {
                transform-origin: 50% 40%;
                transform: rotateX(180deg);
              }
            }
            .filter-target {
              display: block;
            }
          }
          @include tablet-plus {
            // @##catalogue-filter-nav .filter
            a.current,
            a.child-current {
              &::after {
                transform-origin: 50% 40%;
                transform: rotateX(180deg);
              }
            }
            a.current + .filter-target,
            a.child-current + .filter-target {
              display: block;
            }
            // little bit misleading -
            // just hijack the 'open' state and reverse it to close if its current
            &.state-filter-open a.current,
            &.state-filter-open a.child-current {
              &::after {
                transform-origin: 50% 50%;
                transform: rotateX(0deg);
              }
            }
            &.state-filter-open a.current + .filter-target,
            &.state-filter-open a.child-current + .filter-target {
              display: none;
            }
          }
        }
      }
    }
    @include tablet {
      // @.catalogue-filters
      @include cols(6);
    }
    @include desktop-plus {
      // @.catalogue-filters
      @include cols(6);
    }
  }
  .catalogue-items {
    min-height: 50vh;
    .no-items {
      padding: $rhythm;
      h1 {
        max-width: 20em;
        @include set-font(03);
      }
    }
    .page-items {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      flex-wrap: wrap;
    }
    .product {
      @include product-listing;
      margin-bottom: $rhythm * 2;
      border-top: 1px solid $hairline;
    }
    .more {
      display: block;
      width: 100%;
      padding: $rhythm;
      text-align: center;
      @include set-font(02);
      text-transform: uppercase;
    }
    @include phone {
      // @.catalogue-items
      $cf-cols: 24;
      $cf-count: 2;
      // margin-left: -$rhythm;
      // margin-right: -$rhythm;
      .product {
        @include cols($cf-cols/$cf-count, $cf-cols);
        &:nth-child(#{$cf-count}n + 1) {
          border-right: 1px solid $hairline;
          padding-left: 0;
        }
        &:nth-child(#{$cf-count}n + 2) {
          padding-right: 0;
        }
        &:nth-child(1),
        &:nth-child(2) {
          border-top: 0;
        }
      }
    }
    @include tablet-plus {
      // @.catalogue-items
      position: relative;

    }

    @include tablet {
      // @.catalogue-items
      $cf-cols: 18;
      $cf-count: 2;
      @include cols($cf-cols);
      .product {
        @include cols($cf-cols/$cf-count, $cf-cols);
        &:nth-child(#{$cf-count}n + 1) {
          border-right: 1px solid $hairline;
        }
      }
      @for $i from 1 through $cf-count {
        .page-items:first-child .product:nth-child(#{$i}) {
          border-top: 0;
        }
      }
    }
    @include desktop-plus {
      // @.catalogue-items
      $cf-cols: 18;
      $cf-count: 3;
      @include cols($cf-cols);
      .product {
        @include cols($cf-cols/$cf-count, $cf-cols);
        &:nth-child(#{$cf-count}n + 1),
        &:nth-child(#{$cf-count}n + 2) {
          border-right: 1px solid $hairline;
        }
      }
      @for $i from 1 through $cf-count {
        .page-items:first-child .product:nth-child(#{$i}) {
          border-top: 0;
        }
      }
    }
  }
}
