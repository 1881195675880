@mixin set-font($type) {
  @if $type == 00 {
    @include font(13, 1.15);
  }
  @if $type == 01 {
    @include font(15, 1.15);
  }
  @if $type == 02 {
    @include font(17, 1.15);
  }
  @if $type == 03 {
    @include font(24, .95);
  }
  @if $type == 04 {
    @include font(31, .95);
  }
  @if $type == 05 {
    @include scalable-font(43, 31, .95);
    letter-spacing: -.02em;
  }
  @if $type == 06 {
    @include scalable-font(54, 31, .8);
    letter-spacing: -.02em;
  }
}

@mixin black-button {
  border: 0;
  background: $black;
  color: $white;
  padding: 1em $rhythm;
  border-radius: 5px;
  text-align: center;
  display: block;
  width: 100%;
  @include set-font(02);
  .no-touchevents &:hover {
    background: $gray;
  }
}

@mixin naked-button {
  border: 0;
  background: transparent;
  color: $black;
  padding: 1em $rhythm;
  border-radius: 5px;
  text-align: center;
  display: block;
  width: 100%;
  @include set-font(02);
  .no-touchevents &:hover {
    background: $almost-white;
  }
}

@mixin product-listing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $rhythm * .75;
  &:not(.sold-out) {
    @include hover-color($gray);
  }
  &.sold-out {
    color: $gray-medium;
  }
  &.accessory {
    header {
      max-width: 10em;
    }
  }
  header {
    margin-bottom: $rhythm * .5;
  }
  .title-product {
    @include set-font(03);
    margin-bottom: .1em;
  }
  .detail {
    @include set-font(02);
    line-height: 1.2;
    margin-bottom: .1em;
  }
  .sold-out-text {
    color: $black;
    white-space: nowrap;
  }
  .price {
    @include set-font(02);
  }
  .rating {
    @include set-font(00);
  }
  @include phone {
    // @product-listing
    .title-product {
      margin-bottom: 0;
    }
  }
  @include tablet-plus {
    // @product-listing
    .detail {
      display: inline-block;
      vertical-align: top;
      width: 60%;
      padding-right: $rhythm;
    }
    .price {
      display: inline-block;
      vertical-align: top;
      width: 40%;
    }
  }
}

@mixin phone-slide {
  position: fixed;
  height: auto !important; // override style=''
  top: 0;
  bottom: 0;
  right: 0;
  left: $rhythm * 3;
  background: $almost-white;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: 100;
  transform: translateX(100%);
  transition: transform 400ms;
  padding-top: $rhythm * 2;
  padding-bottom: $rhythm * 2;
}
