// NOTE cart /checkout page all in one

.cart-content {
  header {
    h1 {
      @include set-font(05);
    }
  }
  form {
    .confirm {
      margin-bottom: $rhythm * 2;
      p {
        margin: 0;
      }
    }
    a {
      @include hover-underline;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
  .cart {
    border-bottom: 1px solid $hairline;
    padding-top: $rhythm;
    padding-bottom: $rhythm;
    .line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: $rhythm * .5;
      padding-bottom: $rhythm * .5;
      margin-top: $rhythm * .5;
      &:not(:first-child) {
        border-top: 1px solid $hairline;
      }
      &.dependent {
        border-top: 1px dashed $hairline;
      }
      > * {
        width: 10em;
        max-width: 33%;
      }
      .product {
        width: 14em;
      }
      .substitute {
        display: block;
        width: 50%;
        max-width: 18.5em;
        label {
          display: block;
          width: 100%;
        }
        .select-mask {
          max-width: 400px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 4px;
          min-height: 40px;
        }
        button {
          @include hover-underline-button;
          vertical-align: middle;
        }
      }
      .quantity {
        text-align: right;
        display: flex;
        align-items: center;
        input {
          width: 3.5em;
          margin-right: 4px;
        }
      }
      .price {
        text-align: right;
      }
      .remove {
        text-align: right;
      }
      button {
        @include hover-underline-button;
      }
      @include phone {
        flex-wrap: wrap;
        .product {
          width: 100%;
          max-width: none;
          margin-bottom: $rhythm * .5;
        }
        .substitute {
          width: calc(100% - 8em);
          max-width: none;
        }
      }
    }
    .discounts,
    .shipping {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include phone {
        flex-wrap: wrap;
        > * {
          width: 100% !important;
        }
      }
      margin-bottom: $rhythm;
      .code,
      .options {
        width: calc(100% - 8em);
        margin-bottom: $rhythm;
        label {
          display: block;
        }
        [name='codes'],
        .select-mask {
          max-width: 400px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 4px;
        }
        .select-mask {
          min-height: 40px;
        }
        input[type='submit'] {
          @include hover-underline-button;
          vertical-align: middle;
        }
      }
      .value {
        margin-top: 1em;
        text-align: right;
        margin-bottom: 1.4rem;
      }
    }
    .discounts {
      border-top: 1px solid $hairline;
      padding-top: $rhythm * .5;
      margin-top: $rhythm * 2;
    }
    .discount {
      .value {
        &::before {
          content: '-';
        }
      }
    }
    .attributes {
      border-top: 1px solid $hairline;
      margin-top: $rhythm;
      padding-top: $rhythm;
      text-align: right;
    }
    .total {
      margin-top: $rhythm * .25;
      h1 {
        @include set-font(03);
      }
      text-align: right;
    }
  }

  .flexitarian {
    h2 {
      @include set-font(03);
    }
    h3 {
      @include set-font(02);
    }
    form {
      input[type='submit'] {
        @include black-button;
        padding: 12px;
        max-width: 200px;
        margin-bottom: $rhythm * 0.4;
      }

      .checkout-next {
        input[type='submit'] {
          display: block;
          margin-right: 0.5em;
          width: 100%;
          max-width: 200px;

          &:last-child {
            margin-right: 0;
          }

          & > input[type='submit'] {
            margin: 0 0 6px;
          }
        }
      }
    }
    .user {
      .logged-in {
        a {
          @include hover-underline;
        }
      }
    }
    .info {
      .save-details {
        .inner {
          display: none;
        }
        &.state-enabled {
          .inner {
            display: block;
          }
        }
      }
    }
  }
  @include tablet-plus {
    // @.cart-page
    header {
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
    }
    .cart {
      width: 100%;
      max-width: 900px;
      margin: 0 auto
    }
    .flexitarian {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .user {
        width: 400px;
        max-width: columns(10);
        border-right: 1px solid $hairline;
        padding-right: $rhythm;
        padding-top: $rhythm;
      }
      .info {
        width: 500px;
        max-width: columns(14);
        padding-left: $rhythm;
        padding-top: $rhythm;
      }
    }
  }
}
