.wishlist-content {
  .no-results {
    text-align: center;
    margin-bottom: $rhythm * 10;
  }
  .wishlist {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    a {
      border: 0;
    }
    .product {
      @include product_listing;
      form {
        margin-top: $rhythm;
        input[type='submit'] {
          @include naked-button;
        }
      }
    }
    @include tablet-plus {
      // @.wishlist
      $cf-cols: 24;
      $cf-count: 3;
      @include cols($cf-cols);
      .product {
        @include cols($cf-cols/$cf-count, $cf-cols);
        border-bottom: 1px solid $hairline;
        &:nth-child(#{$cf-count}n + 1),
        &:nth-child(#{$cf-count}n + 2) {
          border-right: 1px solid $hairline;
        }
        @for $i from 1 through $cf-count {
          &:nth-last-child(#{$i}) {
            border-bottom: 0;
          }
        }
      }
    }
    @include desktop-plus {
      // @.wishlist
      $cf-cols: 24;
      $cf-count: 4;
      @include cols($cf-cols);
      .product {
        @include cols($cf-cols/$cf-count, $cf-cols);
        border-bottom: 1px solid $hairline;
        &:nth-child(#{$cf-count}n + 1),
        &:nth-child(#{$cf-count}n + 2) {
          border-right: 1px solid $hairline;
        }
        @for $i from 1 through $cf-count {
          &:nth-last-child(#{$i}) {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
